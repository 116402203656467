.menu_bouton, .sidebar .boutons {
	///font-size: 1vw;
	z-index: 1;
	position: fixed;
	bottom: 1rem;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	.bouton{
		position: relative;
		cursor: pointer;
		text-transform: uppercase;
		//width: 60px;
		//height: 60px;
		//border-radius: 50%;
		//transition:all 0.1s;
		font-size: 35px;
		padding: 0.5rem 0.8rem;
		border-radius: 20px;
		box-shadow: 0 0.3em 1em rgba(0, 0, 0, 0.2);
		border: 10px solid $rouge1;
		background-color: $blanc;
		color: $rouge1;

		@media #{$md} {
			font-size: 8vh;
			padding: 0vh 3vh;
			border-radius: 10vh;
			box-shadow: 0 0.3vh 1vh rgba(0, 0, 0, 0.2);
		}
		.boutonOverlay{
			@include position(absolute, 0% 0% 0% 0%);
			border-radius: 50%;
			opacity: 1;
			//z-index: 2;
		}
		span{
			position: relative;
			//z-index: 3;
		}
		&:hover{
			background-color: $rouge1;
			color: $blanc;
			border: 10px solid $blanc;
			transform: scale(1.1);
		}
	}
}


.sidebar{	
	background-color: $rouge1;
	@include position(fixed,0% 0% auto auto);
	height: 100%;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 200;
	transition:all 0.25s;
	&.showSidebar{
		z-index: 200;
		visibility: visible;
		opacity: 1;
		transition:all 0.25s 0.1s;
	}
	#carteSection{
	}
	.boutons{
		//@include position(fixed, 2% auto auto 2%);
		.bouton {
			/* width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: $blanc;
			.boutonOverlay{
				box-shadow: 0 0.3em 1em rgba(0,0,0,0.2);
			}
			span{
				color: $rouge1;
				font-size: 60px;
			}
			&:hover{
				background-color: $rouge1;
				span{
					color: $blanc;
				}
			} */
		}
	}
}

body{
	overflow-y: auto;
	.sidebar{
		overflow-y: hidden;
	}
	.nav{
		z-index: 100;
	}
	&.overlayed{	
		overflow-y: hidden;	
		.sidebar{
			overflow-y: auto;
		}
		.nav{
			z-index: 100;
		}
	}
}

.nav{
	@include position(fixed, 0% 0% auto auto);
	opacity: 0;
	visibility: hidden;
	.boutons{
		//transform: translateY(-100%);
		margin-top: 1em;
		margin-right: 1em;
		//@include position(absolute,5% 50% auto auto );
		.bouton{
			//background-color: $rouge1;
			color: $blanc;
			span{
				opacity: 1;
				transition:all 0.35s;
				z-index: 2;
			}
			& + .bouton{
				margin-left: 5em;
			}
			.boutonOverlay{	
				width: 100%;
				height: 100%;
				//overflow: hidden;	
				visibility: hidden;
				//opacity: 0;	
				transform-origin:center center;		
				transform:scale(1);
				z-index: 1;
				transition: all 0.2s 0.15s;
			}
			&.goVerlay{
				.boutonOverlay{	
					visibility: visible;
					//opacity: 1;
					transition: all 0.5s;
					transform:scale(130);
					z-index: 1;
				}
			}
		}
		&.overlayOpened{
			.bouton{
				span{
					opacity: 0;
					transition:all 0.35s;
					z-index: 2;
				}
			}
		}
	}
}
	


#carteSection{
	font-size: 1.4vw;
	width: 100%;	
	padding-top: 5em;
	padding-bottom: 20em;
	#carte{  
		position: relative;  
	    @media #{$xl}{
	    	max-width: 1000px;
	    	font-size: 12px;
	    }
    	width: 90%;
	    margin: 0 auto;
	    .bgShadowCarte{
			@include position(absolute,0% 0% 0% 0%);
			transform:scale(.9) translate(0%,-8%);
			display: block;
			background-color: rgba($noir,0.3);
			filter:blur(1em);
	    }
	}
}

.level1{
	//height: 15vw;
	.inner{
		.headerMenu{
			overflow: hidden;
			cursor: pointer;
			//height: 20em;		
			.photoBg{
				@include position(absolute,-175% -20% 0% auto);
				filter:blur(0.1em);
				height: 400%;
				width: 100%;
				img{
					//@include position(absolute,-175% auto auto 60%);
					width: auto;
					height: 100%;	
				}
			}
			.headerBg{
				@include position(absolute,0% 0% 0% 0%);
				opacity: 0.9;	
			}
			.headerMenuText{
				position: relative;
				height: 100%;
				.photoMenu{
					position: relative;
					height: 100%;
					width: 30%;	
					//padding-left: 6%;	
					.photoMenuCadre, .photoMenuShadow{}
					.photoMenuCadre{
						@include position(absolute,10% 50% 10% 10%);
						background-color: rgba($blanc,0.7);
					}
					.photoMenuShadow{
						@include position(absolute,auto auto 15% 25%);
						width: 50%;	
						height: 10%;	
						border-radius: 50%;	
						background-color: rgba($noir,0.6);
						filter:blur(0.4em);
					}
						img{
							position: relative;
						    height: auto;
	    					width: 100%;
	    					min-width: 30px;
						}
				}
				.titreMenu{
					//width: 60%;
					overflow: hidden;
					&.f100 {
						font-size: 100%;
					}
					&.f80 {
						font-size: 50%;
						padding: 2rem 2rem;
					}
					.titreMenuH2{
						h2{
							white-space: nowrap;
							font-weight: 400;	
							color:$blanc;
							font-size: 8em ;	
							letter-spacing: 0.05em;
							text-shadow: 0 0 0.5em rgba($noir,0.3);	
						}
					}
				}
				.headerCol{
					opacity: 0;
					visibility: hidden;
					width: 40%;
					@include position(absolute, auto 0% 5% auto);
					text-align: right;
					padding-right: 1em;
					transition: all 0.6s ease-in-out;
					//height: 60px;
					font-size: 2.6em ;
					> div {
						width: 50%;
						> span{line-height: 1;
							white-space: nowrap;	}
					}
				}

			}
		}
		.itemsMenu{
			//display: none;
			//transform:scaleY(0);
			height: 0px;
			//transform-origin:center top;
			transition: all 0.6s ease-in;
			transform-style: preserve-3d;
			overflow: hidden;
			.lvl2{		
				perspective:80em;
				perspective-origin:center top;
				overflow: hidden;	
				transform-style: preserve-3d;
				height: 80px;
				
				.lvl2Inner{
					height: 100%;
					overflow: hidden;
					transform-style: preserve-3d;
					transition: all 0.6s ease-in;
					padding-left: 1em;
					padding-right: 1em;
					font-size: 3em;
    				backface-visibility: hidden;  
					.basePlat{
						font-size: 100%;
						@media #{$md} {
							font-size: 80%;
						}
						line-height: 1;
						//color: #fff;
						//padding: 0.5rem rem 2rem;
					}
					.titrePlat{
						line-height: 1;
						width: 60%;
						span{line-height: 1;}
					}
					.prixSeulPlat{
						text-align: right;
						width: 20%;
						span{line-height: 1;}
					}
					.prixMenuPlat{
						text-align: right;
						width: 20%;
						span{line-height: 1;}
					}
					.linePlat{
						@include position(absolute, auto auto 0% 0%);
						opacity: 0.5;
						height: 0.075em;
						width: 100%;
					}
				}
				&:nth-of-type(2n+1) {.lvl2Inner{transform-origin:center top;transform:rotateX(-90deg);  	/* box-shadow:inset 0  1em 1em rgba(0,0,0,0.4); */}}
				&:nth-of-type(2n) 	{.lvl2Inner{transform-origin:center bottom;transform:rotateX(90deg) ;	/* box-shadow:inset 0 -1em 1em rgba(0,0,0,0.2); */}}
			}
		}
	}
	&.activeLevel1{
		.inner{
			.headerMenuText{
				.headerCol{
					opacity: 1;
					visibility: visible;
				}
			}
			.itemsMenu{
				transition: all 0.6s ease-out;
				//transform:scaleY(1);
				.lvl2{			
					
					&:nth-of-type(2n+1) {.lvl2Inner{transform-origin:center top;transform:rotateX(0deg);	/* box-shadow:inset 0  0em 0em rgba(0,0,0,0) */;}}
					&:nth-of-type(2n) 	{.lvl2Inner{transform-origin:center bottom;transform:rotateX(0deg);	/* box-shadow:inset 0  0em 0em rgba(0,0,0,0) */;}}
					.lvl2Inner{
						transition: all 0.6s ease-out;
					}
				}
			}
		}
	}
}

