

.max-width{
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		&.60vw{
			width: 60vw;
		}
		&.80vw{
			width: 80vw;
		}
		&.w80{
			width: 80%;
		}
		&.sm{
			max-width: 544px;
		}
		&.md{
			max-width: 768px;
		}
		&.lg{
			max-width: 992px;
		}
		&.xl{
			max-width: 1280px;
		}
		&.xxl{
			max-width: 1920px;
		}
		&.xxxl{
			max-width: 2000px;
		}
}

.h100 {height: 100%;}
.w100 {width: 100%;}

.vh100{height: 100vh;}

.img_respw{    
	max-width: 100%;
	height: auto;
}
.img_resph{    
	max-height: 100%;
	width: auto;
}
.img_fullw{
	width: 100%;
	height: auto;
}
.img_fullh{
	height: 100%;
	width: auto;
}

.posr{position: relative;}
.posa{position: absolute;}
.posf{position: fixed;}

.db{
	display: block;
}
.dn{
	display: none;
}
.dib{
	display: inline-block;
}

.border{
	&.top{
		border-top-width: 1px;
	}
	&.left{
		border-left-width: 1px;
	}
	&.bottom{
		border-bottom-width: 1px;
	}
	&.right{
		border-right-width: 1px;
	}
	&.solid{
		border-style: solid;
	}
}

.ovh{overflow: hidden;}
.ovxh{overflow-x: hidden;}
.ovyh{overflow-y: hidden;}
.ovxa{overflow-x: auto;}
.ovya{overflow-y: auto;}



.md_down_hide{
	display: none;
	@media #{$md}{
		display: block;
	}
}



// hide visually, but available for screenreaders
.visuallyhidden,
%visuallyhidden {
	position: absolute;
	margin: -1px;
	padding: 0;
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

// extends the .visuallyhidden class to allow the element to
// be focusable when navigated to via the keyboard
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	position: static;
	margin: 0;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
}
