* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  //font-size: 62.5%;
  //background: radial-gradient(ellipse at bottom, $base 0, $darken 80%, $back 100%);
}
body,.sidebar {
  &::-webkit-scrollbar {
      width: 12px;
  }
   
  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
      background-color: rgba($blanc,1);
      border-radius: 10px;
  }
   
  &::-webkit-scrollbar-thumb {
      background-color: $rouge1;

      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }
}
body,html{
	width: 100%;
	//min-height: 100vh;
	//overflow-x: hidden;
  	@extend .regular;
}
main{
	background: linear-gradient(45deg, rgba(175,175,175,0.65) 0%,rgba(0,0,0,0) 100%);
}