//Responsive variable
$xs: "(min-width: 0px)";
$sm: "(min-width: 544px)";
$md: "(min-width: 768px)";
$lg: "(min-width: 992px)";
$xl: "(min-width: 1280px)";
$xxl: "(min-width: 1920px)";
$xxxl: "(min-width: 3000px)";

$couleur1: #33382d;

$noir: #000;
$blanc: #fff;

$base : #F7F6F0;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);

$gris1	: #868686;
$gris2 : darken($gris1, 10%);
$gris3 : darken($gris2, 10%);

$rouge1	: #e84e38;
$rouge2 : darken($rouge1, 10%);
$rouge3 : darken($rouge2, 10%);

$c1	: #d51116;
$c2	: #c51162;
$c3	: #6c4595;
$c4	: #76b72a;
$c5	: #4c4394;
$c6	: #3b56a3;
$c7	: #3b5fa9;
$c8	: #328aca;
$c9	: #08b7d3;
$c10: #31b39f;
