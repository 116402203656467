
@mixin makeColorclass($nomCouleur, $couleur){
	.#{$nomCouleur}{ color : $couleur;}
	.bg-#{$nomCouleur}{ background-color : $couleur;}
	.bor-#{$nomCouleur}{ border-color : $couleur;}
	.fill-#{$nomCouleur}{ fill : $couleur;}
	.stroke-#{$nomCouleur}{ stroke : $couleur;}

	.#{$nomCouleur}-op1{ color : rgba($couleur, 0.9);}
	.bg-#{$nomCouleur}-op1{ background-color : rgba($couleur, 0.9);}
	.bor-#{$nomCouleur}-op1{ border-color : rgba($couleur, 0.9);}

	.#{$nomCouleur}-op2{ color : rgba($couleur, 0.8);}
	.bg-#{$nomCouleur}-op2{ background-color : rgba($couleur, 0.8);}
	.bor-#{$nomCouleur}-op2{ border-color : rgba($couleur, 0.8);}

	.#{$nomCouleur}-op3{ color : rgba($couleur, 0.7);}
	.bg-#{$nomCouleur}-op3{ background-color : rgba($couleur, 0.7);}
	.bor-#{$nomCouleur}-op3{ border-color : rgba($couleur, 0.7);}

	.#{$nomCouleur}-op4{ color : rgba($couleur, 0.6);}
	.bg-#{$nomCouleur}-op4{ background-color : rgba($couleur, 0.6);}
	.bor-#{$nomCouleur}-op4{ border-color : rgba($couleur, 0.6);}

	.#{$nomCouleur}-op5{ color : rgba($couleur, 0.5);}
	.bg-#{$nomCouleur}-op5{ background-color : rgba($couleur, 0.5);}
	.bor-#{$nomCouleur}-op5{ border-color : rgba($couleur, 0.5);}
}

$colors-list:'couleur1','noir','blanc','gris1','gris2','gris3','rouge1','rouge2','rouge3','c1','c2','c3','c4','c5','c6','c7','c8','c9','c10';
$colors-var: $couleur1, $noir, $blanc, $gris1, $gris2, $gris3, $rouge1, $rouge2, $rouge3, $c1, $c2, $c3, $c4, $c5, $c6, $c7, $c8, $c9, $c10;


@for $i from 1 through length($colors-list) {
    @include makeColorclass (nth($colors-list, $i),nth($colors-var, $i));
}

$kids:#5B6AB0;
$assiette:#8F4E99;
$burger:#E9407A;
$panini:#3EBCD3;
$tacos:#F7A52D;
$sauces:#788F9B;
$salade:#67B86A;
$fried_food:#EE6F49;
$kebab:#EA5351;
$galette:#23A69A;
$extra:#3774d7;


.kids{.color{color:$kids;}.bg-color{background-color:$kids;}.bor-color{border-color:$kids;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($kids,25%) 0, $kids 100%)}}
.assiette{.color{color:$assiette;}.bg-color{background-color:$assiette;}.bor-color{border-color:$assiette;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($assiette,25%) 0, $assiette 100%)}}
.burger{.color{color:$burger;}.bg-color{background-color:$burger;}.bor-color{border-color:$burger;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($burger,25%) 0, $burger 100%)}}
.panini{.color{color:$panini;}.bg-color{background-color:$panini;}.bor-color{border-color:$panini;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($panini,25%) 0, $panini 100%)}}
.tacos{.color{color:$tacos;}.bg-color{background-color:$tacos;}.bor-color{border-color:$tacos;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($tacos,25%) 0, $tacos 100%)}}
.sauces{.color{color:$sauces;}.bg-color{background-color:$sauces;}.bor-color{border-color:$sauces;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($tacos,25%) 0, $tacos 100%)}}
.salade{.color{color:$salade;}.bg-color{background-color:$salade;}.bor-color{border-color:$salade;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($salade,25%) 0, $salade 100%)}}
.fried_food{.color{color:$fried_food;}.bg-color{background-color:$fried_food;}.bor-color{border-color:$fried_food;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($fried_food,25%) 0, $fried_food 100%)}}
.kebab{.color{color:$kebab;}.bg-color{background-color:$kebab;}.bor-color{border-color:$kebab;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($kebab,25%) 0, $kebab 100%)}}
.galette{.color{color:$galette;}.bg-color{background-color:$galette;}.bor-color{border-color:$galette;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($galette,25%) 0, $galette 100%)}}
.extra{.color{color:$extra;}.bg-color{background-color:$extra;}.bor-color{border-color:$extra;}.bgr-color{background: radial-gradient(ellipse at bottom, lighten($extra,25%) 0, $extra 100%)}}

.nos_viandes,
.nos_fromages,
.nos_vege_et_autre,
.supplement_gratine {
	@extend .extra;
}
.boissons_soft,
.boissons_alcoolisees {
	@extend .panini;
}