// gradients
@mixin gradient($angle, $colour1, $colour2) {
  background: $colour1;
  background: linear-gradient(#{$angle}deg, $colour1 0%, $colour2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$colour1', endColorstr='$colour2',GradientType=1 );
}

// Import font
@mixin fontfaceGenerator($fontName){
	@font-face {
	  font-family: $fontName;
	  src: url('../fonts/#{$fontName}.eot');
	  src: url('../fonts/#{$fontName}.eot?#iefix') format('embedded-opentype'),
	       url('../fonts/#{$fontName}.woff') format('woff'),
	       url('../fonts/#{$fontName}.ttf') format('truetype'),
	       url('../fonts/#{$fontName}.svg##{$fontName}') format('svg');
	  font-weight: normal;
	  font-style: normal;
	}	
}

// Position 
@mixin position ($position: relative, $coordinates: 0 0 0 0) {
    @if type-of($position) == list {
        $coordinates: $position;
        $position: relative;
    }

    $top: nth($coordinates, 1);
    $right: nth($coordinates, 2);
    $bottom: nth($coordinates, 3);
    $left: nth($coordinates, 4);

    position: $position;

    @if $top == auto {
        top: $top;
    }
    @else if not(unitless($top)) {
        top: $top;
    }
    @if $right == auto {
        right: $right;
    }
    @else if not(unitless($right)) {
        right: $right;
    }
    @if $bottom == auto {
        bottom: $bottom;
    }
    @else if not(unitless($bottom)) {
        bottom: $bottom;
    }
    @if $left == auto {
        left: $left;
    }
    @else if not(unitless($left)) {
        left: $left;
    }
}

