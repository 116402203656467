.nav {
    width: 100%;
    .boutons{
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 0.6rem;
        @media #{$md} {
            gap: 1rem;
        }
        .iconLine {
            line-height: 1;
            a,span  {
                display: block;
                background-color: #fff;
                padding: 0.5rem;
                border-radius: 50%;
                text-decoration: none;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.3rem;
                height: 3.3rem;
                @media #{$md} {
                    width: 5rem;
                    height: 5rem;
                }
                i {
                    display: inline-block;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                
            }

            a:hover {
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
                transform: scale(1.1);
            }

            span {}
        }
    }
}
