
.immeubles{
	//transform: scale(4);
	//transform-origin: 0% 0%;
		
	.wrapper{
	}
}

#introSection{
	position: relative;
	height: 99%;
	width: 100%;
	//min-width: 100vw;
	//min-height: 100vh;
	#viewport{
		overflow: hidden;
		perspective: 200em;
			perspective-origin: 50% 100%;
		transform-style: preserve-3d;
		//transform: translate3d(0,0,0);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		#scene{
			//perspective:700px;
			position: relative;
			width: 100%;
			height: 100%;
			transform-origin: center bottom;
    		transform: translateZ(0em);
			> *{
				overflow: hidden;
				@include position(absolute, 0% 0% 0% 0%);
				height: 100%;
				width: 100%;
			}
		}
	}
}



#bgScene{
	background: radial-gradient(ellipse at 50% 80%, $blanc 0, #6db2d0  100%);
}

#introSection{
	#viewport{
		#scene{
			font-size: 1vw;
		}
	}
}


#logoScene{
	#logo_wrapper{
		@include position(absolute, 20% auto auto 50%);
		width: 80%;transform: translate(-50%,-20%);
		//@media #{$md}{width: 40em;transform: translate(30em,-30%);}
		transform-origin:center center;
		opacity: 0;
		text-align: center;
		svg{
			position: relative;
			width:100%;
			height: auto;
			path{		
				fill-opacity:0;
				stroke-width:1;
				stroke-miterlimit:4;
				stroke-opacity:0;				
			}
		}
		.slogan{
			position: relative;
			#slogan{
				//opacity: 0;
				font-size: 2em;
				letter-spacing: 0.6em;
			}
		}
	}
}
#skySnene{
	#sun{
		@include position(absolute, 20% auto auto 50%);
		transform: translate(-50%,-150%) rotate(0deg);
		transform-origin:center center;
		width: 80em;
		img{
			width: 100%;
			height: auto;
		}
	}
	/* #nuages_wrapper{
		//@include position(absolute, 0% auto auto 0%);
		width: 100em;
		.nuage{
			width: 30em;			
			opacity: 1;
			&#nuage_1{opacity: 0;@include position(absolute, 0% auto auto 0%);		transform: translate(2em,-100em);	img{filter:blur(0.45em);}}
			//&#nuage_2{opacity: 0;@include position(absolute, 10% 0% auto auto);		transform: translate(0%,30%);	img{filter:blur(0.65em);}}
			img{
				width: 100%;
				height: auto;
			}
		}
	} */
}
#chappelleScene{
	#chappelle_wrapper{
		transform-origin:left bottom;
		@include position(absolute, auto 0% 0% 0%);		
		transform: translate(5em,0em) scaleX(1) scaleY(0);
		svg{
			position: relative;
			height:auto;
			width: 100%;
		}
	}
}
.immeublePlan{
	.immeubles_wrapper{
		transform: translate(0em,0em) scaleX(1) scaleY(0);
		svg{
			position: relative;
			height:auto;
			g.wrapper{
				g{
					&.fenetres{
						fill-opacity:0.5;
						fill:yellow;
					}
					&.porte{
						fill-opacity:0.5;
						fill:orange;
					}
					path{}
				}
			}
		}
	}
}
.immeublePlan .immeubles_wrapper{
	transition:width 0.5s;
	@media (orientation:landscape){		width: 100em;	}
	@media (orientation:portrait){		width: 200em;	}

}
 #chappelleScene, #chappelle_wrapper{
	transition:width 0.5s;
	@media (orientation:landscape){		width: 30em; 	}
	@media (orientation:portrait){		width: 70em; 	}

} 

#immeubleScene3{
	.immeubles_wrapper{
		transform: translate(0em,0em) scaleX(1) scaleY(0) rotateY(180deg);
		transform-origin:center bottom;
		@include position(absolute, auto auto 0% 0%);
		svg{
			width: (100% / 2);
			.mur{
				fill:$gris3;
			}
		}
	}
}
#immeubleScene2{
	.immeubles_wrapper{
		transform: translate(0em,0em) scaleX(1) scaleY(0) rotateY(180deg);
		transform-origin:center bottom;
		@include position(absolute, auto 0% 0% auto);
		svg{
			width: (100% / 3);
			.mur{
				fill:$gris2;
			}
		}
	}
}
#immeubleScene1{
	.immeubles_wrapper{
		transform-origin:center bottom;
		@include position(absolute, auto 0% 0% 0%);
		svg{
			width: (100% / 4);
			.mur{
				fill:$gris1;
			}
		}
	}
}

.plan1{filter: blur(0.05vh);}
.plan2{filter: blur(0.15vh);}
.plan3{filter: blur(0.25vh);}
.plan4{}
.plan5{filter: blur(0.30vh);}

#menu3dScene{
	display: none;
	visibility: none;
	font-size: 1vw;
  	width: 10em;
  	height: 10em;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%,-50%);
  	//perspective: 200em;
    //transform-style: preserve-3d;
	#menu3d{
	    //transform: rotatex(20deg) translatez(1em);
		$numOfElems: 9;
		@for $i from 1 through $numOfElems {
			$deg: 360deg / $numOfElems;
			.photo:nth-child(#{$i}) {
				transform: rotate($deg/-2 + $deg * ($i - 1));
			}
			
			&.active {
			    .photo:nth-child(#{$i}) .photo_inner {
			      transform: translateY(-20em) rotate($deg/2 - $deg * ($i - 1)); 
			    }
	  		}
		}
		.photo{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform-origin: 50% 50%;
			.photo_inner{
				    position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    height: 100%;
				    border-radius: 50%;
				    //background: rgba(255,255,255, 0.15);
				    //opacity: 0;
				    //z-index: -10;
				    cursor: pointer;
				    .photoBg{
					    //transform: translate(0%) rotatex(60deg);
					    filter:blur(0.05em);
					    width: 100%;
					    bottom: 0%;
					    height: 30%;
					    border-radius: 50%;
					    display: block;
					    position: absolute;
					    z-index: -1;
				    }
				img{
					display: inline-block;
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
