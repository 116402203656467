//@include fontfaceGenerator('barroco');
//@include fontfaceGenerator('PatrickHandSC-Regular');

.regular{
	font-family: 'Patrick Hand SC', cursive;
	//text-transform: uppercase;
}

.regular2{
	font-family: 'Barlow Condensed', sans-serif;
}

//.regular{@include regular;font-weight: 300;}
//.semi_bold{@include regular;font-weight: 700;}
//.extra_bold{@include regular;font-weight: 800;}

.caps{text-transform: uppercase;}
.tal{text-align: left;}
.tac{text-align: center;}
.tar{text-align: right;}