//----------------------------------*\
// Grid en flex + resp
//----------------------------------*/


$margin:  1 !default;
$margin:  'xs' !default;

@mixin gridMaker($col, $margin,$size_screen){
    .#{$size_screen}-grid-#{$col}{
        font-size: 1vw;
        width: 100%;
        > * {
            width: ((100/$col ) - ($margin * 2));
            margin: $margin;
            > * {
                //font-size: (100 / $col) * 6%;
            }
        }
    }
}
@mixin gridMakerSimple($col, $margin){
    .grid-#{$col}{
        width: 100%;
        > * {
            font-size: 1vw;
            width: ((100/$col ) - ($margin * 2));
            margin: $margin;
            > * {
                //font-size: (100 / $col) * 6%;
            }
        }
    }
}

@for $i from 2 through 12 {    @include gridMakerSimple(#{$i},1);    }
@media #{$xs}{    @for $i from 2 through 4 {    @include gridMaker(#{$i},1,'xs');    } }
@media #{$sm}{    @for $i from 2 through 6 {    @include gridMaker(#{$i},1,'sm');    } }
@media #{$md}{    @for $i from 2 through 8 {    @include gridMaker(#{$i},1,'md');    } }
@media #{$lg}{    @for $i from 2 through 10 {    @include gridMaker(#{$i},1,'lg');    } }
@media #{$xl}{    @for $i from 2 through 12 {    @include gridMaker(#{$i},1,'xl');    } }