#infoSection{
	font-size: 0.5rem;
	padding-bottom: 15rem;
	@media #{$md}{
		font-size: 0.9vw;
	}
	#sceneWrapper{
		.bgWrapper{
			@include position(absolute, 0% 0% 0% 0%);			
			z-index: -2;
			#bgInfo{
				width: 100%;
				height: 100%;
			}
		}
		.persoBlock{
			position: relative;
			width: auto;
			max-width: 80vmin;
			transform:translateY(10%);
			img{
				//max-width: 800px;
				width: 100%;
				height: auto;
			}
			.personnage{
				@include position(relative, 0% 0% 0% 0%);
				z-index: -1;
			}
			.personnageShadow{
				@include position(absolute, 0% 0% 0% 0%);
				z-index: -2;
				transform:translateX(-0.6em);
				opacity: 0.5;
				filter:blur(0.3em);
			}
		}
		.infoBlock{
			//@include position(absolute, auto 0% 0% auto);
			position: relative;			
			top: 2em;
			//top: 2em;
			//right: 1em;			
			font-size: 3.4em;
			text-align: center;
			//transform: translateY(-10%);
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
			.phone{
				font-size: 120%;
				margin-bottom: 0.5em;
				a{white-space: nowrap;}
			}
			span,a{				
				line-height: 0.9;	
				text-decoration: none;
				white-space: nowrap;
			}
			.surplace{
				display: none;
				position: relative;
				top: 0.15em;
				left: 0.5em;
				@media #{$lg}{
					//display: block;
				}
			}
		}
		
	}
}

.infosAll {
	margin-bottom: 2rem;
	text-align: left;
	.iconLine{
		margin-bottom: 15px;
		line-height: 1;
		i{
			width: 1em;
			margin-right: 0.5em;					
			img{
				max-width: 100%;
				height: auto;
			}
		}
		a{}
		span{}
	}
}

.iconLine{
	display: flex;
	justify-content: flex-start;
	align-items: center;	
}
.icon-phone{
	display: inline-block;
	height: 2em;
	width: auto;
	svg{
		height: 100%;
		width: auto;
	}
}